import React, { useState } from 'react';
import { navigate } from "gatsby";
import { Event } from "./../../helpers/analytics/event-tracking"
import Loader from 'components/loaders/loader'
import Button from 'components/button/button';
import Checkbox from 'components/forms/checkbox';
import { consentForProcessWordingDataPrep, contentForMarketingWording } from 'components/forms/form-helpers';


const UserPrivacyPolicy = ({ data_policy, updatePlatformRegistration, data_prep_registration_reducer }) => {
  const [ userAgreedPolicy, setUserAgreedPolicy ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const onClick = () => {
    if (!userAgreedPolicy)
      return;

    setLoading(true);
    requestRegistration();
  }

  const getCoordinates = (body, callback) => {
    body['latitude'] = null;
    body['longitude'] = null;

    if (process.env.NODE_ENV === 'production') {
      fetch("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBlfCUSm5wQUDUzronbB4godMe2WORDxb0",
        { method:"POST"})
        .then(response => {
          if (response.ok) {
            return response.json()
                          .then(data => {
                            body['latitude'] = data.location.lat;
                            body['longitude'] = data.location.lng;
                            callback(body);
                          });
          }
          else {
            callback(body);
          }
        })
        .catch(err => {
          console.error(err);
          Event('error', 'ml-data-prep user-privacy-policy', 'unable to fetch cordinates')
          body['latitude'] = null;
          body['longitude'] = null;
          callback(body);
        })
    }
    else {
      callback(body);
    }
  }

  const codeHandler = (code, details) => {
    switch (code) {
      case 'K4001':
        updatePlatformRegistration('error', 'That email is already in use')
        Event('error', 'ml-data-prep user-privacy-policy', 'email is already in use')
        navigate('/ml-data-prep-registration/')
        break;
      case 'K4004':
         updatePlatformRegistration('error', 'Your password must contain at least 14 characters, or 8 characters and at least 1 upper case, 1 lower case and a special or numeric character')
         Event('error', 'ml-data-prep user-privacy-policy', 'password invalid')
         navigate('/ml-data-prep-registration/')
        break;
      case 'K4005':
         updatePlatformRegistration('error', 'Email must be a valid email')
         Event('error', 'ml-data-prep user-privacy-policy', 'email invalid')
         navigate('/ml-data-prep-registration/')
        break;
      case 'K1001':
        updatePlatformRegistration('user_details', { position_in_queue: details.position_in_queue });
        navigate('/ml-data-prep-waitlist/')
        break;
      case 'K1000':
        updatePlatformRegistration('user_details', { project_path: details.project_path });
        navigate('/ml-data-prep-welcome/')
        break;
      default:
         updatePlatformRegistration('error', 'Something unexpected went wrong, please try again later')
         Event('error', 'ml-data-prep user-privacy-policy', 'something unexpected went wrong')
        break;
    }
  }

  function registrationCallback(body) {
    fetch(process.env.PLATFORM_HOST + '/api/v1/account/register', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
    })
    .then(data => data.json())
    .then(data => {
      dataLayer.push({'event': 'gtm-ml-data-prep-complete-registration'}); //GTM Google Ads conversion tracking
      codeHandler(data.kortical_response_code, data.details);
      setLoading(false);

    })
    .catch(err => {
      codeHandler('K9999');
      setLoading(false);
    })
  }

  const requestRegistration = () => {
    const body = data_prep_registration_reducer;
    body['job_title'] = body.role;
    body['sign_up_company_name'] = body.company;

    getCoordinates(body, registrationCallback);

  }

  const onChange = (e) => {
    if (e.target.name === 'contentForPrivacyAndTerms')
      setUserAgreedPolicy(e.target.checked);

    if (e.target.name === 'contentForMarketing')
      updatePlatformRegistration('allow_marketing', e.target.checked);
  }

 return (
  <form className="UserPrivacyPolicy col-md-6 col-12 k-bg--grey">
    <div dangerouslySetInnerHTML={{ __html: data_policy }} />
    <Checkbox name="contentForPrivacyAndTerms"
              handleChange={onChange}
              type="checkbox">
        <p dangerouslySetInnerHTML={{ __html: consentForProcessWordingDataPrep }} />
    </Checkbox>
    <Checkbox name="contentForMarketing"
              handleChange={onChange}
              type="checkbox">
        <p dangerouslySetInnerHTML={{ __html: contentForMarketingWording }} />
    </Checkbox>
    <Button disabled={!userAgreedPolicy || loading}
            className=" btn k-text--heading-work btn-block btn-xs-inline-block d-flex align-items-center justify-content-center p-2 mt-4"
            id={`UserPrivacyPolicy__submit`}
            callback={onClick}>
        { loading
          ? <Loader />
          : 'Create Account'
        }
    </Button>
    <p className="text-red mt-2">{data_prep_registration_reducer.error}</p>
  </form>
  )
}

export default UserPrivacyPolicy;